var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"simulator",style:(_vm.tmpBgImage !== '' && _vm.settings && _vm.settings.bg.type === 'image'
      ? { background: "#fff url(" + _vm.tmpBgImage + ") no-repeat center" }
      : {
          background: _vm.settings ? _vm.formattedColor(_vm.settings.bg.value) : '#FFFFFF'
        })},[_c('div',{staticClass:"simulator-title"},[_c('div',{staticClass:"simulator-title-wrapper subtitle-2 font-weight-bold",style:({
        color: _vm.settings ? _vm.formattedColor(_vm.settings.bg.title_color) : '#686868'
      })},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{staticClass:"simulator-video mt-30"},[(_vm.poster)?_c('v-img',{attrs:{"src":_vm.poster,"lazy-src":_vm.poster,"height":"200"}}):_vm._e()],1),_c('div',{staticClass:"simulator-content",style:({
      color: _vm.settings ? _vm.formattedColor(_vm.settings.bg.content_color) : '#686868'
    })},[_c('v-row',[_c('v-col',{staticClass:"caption font-weight-bold",attrs:{"md":"10"}},[_c('div',{staticClass:"content-container"},[_vm._v(" "+_vm._s(_vm.content)+" ")])]),_c('v-col',{attrs:{"md":"2"}},[_c('div',{staticClass:"mb-3"},[_c('v-icon',[_vm._v("mdi-thumb-up-outline")])],1),_c('div',{staticClass:"mb-3"},[_c('v-icon',[_vm._v("mdi-share-variant")])],1),_c('div',[_c('v-icon',[_vm._v("mdi-heart-outline")])],1)])],1)],1),_c('v-bottom-navigation',{staticClass:"simulator-nav",attrs:{"absolute":""}},[_c('v-btn',{attrs:{"value":"recent"}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1),_c('v-btn',{attrs:{"value":"favorites"}},[_c('v-icon',[_vm._v("mdi-youtube-tv")])],1),_c('v-btn',{attrs:{"value":"nearby"}},[_c('v-icon',[_vm._v("mdi-account-outline")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }