<template>
  <form>
    <v-row>
      <v-col class="py-0" md="12">
        <v-row class="pa-0 ma-0">
          <v-btn text color="primary" @click="showSimulator = !showSimulator">
            {{ showSimulator ? "关闭模拟器" : "开启模拟器" }}
          </v-btn>
          <v-spacer />
          <v-btn text color="primary" @click="$router.history.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
            返回列表
          </v-btn>
        </v-row>
      </v-col>
      <v-col md="12" sm="12">
        <v-stepper v-if="form" v-model="currentStep" complete vertical>
          <v-stepper-step step="1">
            1. 背景设置
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-card class="rounded shadow">
              <v-card-text>
                <v-row>
                  <v-col md="6" sm="12">
                    <v-radio-group v-model="form.setting.bg.type">
                      <v-radio value="color">
                        <template v-slot:label>
                          <span>纯色背景</span>
                        </template>
                      </v-radio>
                      <v-radio value="image" class="mt-5">
                        <template v-slot:label>
                          <div>插入背景图片</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col md="6">
                    <v-color-picker
                      v-if="form.setting.bg.type === 'color'"
                      v-model="form.setting.bg.value"
                      class="shadow"
                      dot-size="20"
                      swatches-max-height="100"
                    ></v-color-picker>
                    <div v-else>
                      <v-img
                        v-if="tmpBgImage !== ''"
                        :src="tmpBgImage"
                        :lazy-src="tmpBgImage"
                        width="250"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-divider
                        v-if="
                          form.setting.bg.type === 'image' &&
                            form.setting.bg.value !== ''
                        "
                      />
                      <v-file-input
                        accept="image/*"
                        label="上传背景图片"
                        filled
                        prepend-icon=""
                        dense
                        prepend-inner-icon="mdi-camera"
                        @change="addBgImage"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-stepper-content>
          <v-stepper-step step="2">
            2. 内容设置
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card class="shadow rounded mt-2">
              <v-card-text>
                <v-text-field
                  v-model="form.title"
                  label="请输入视频标题"
                  append-icon="mdi-palette"
                  filled
                  hide-details
                >
                  <template v-slot:append>
                    <v-btn small icon color="primary" @click="setTitleColor">
                      <v-icon>mdi-palette</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
                <v-textarea
                  v-model="form.content"
                  filled
                  hide-details
                  label="输入视频简介"
                >
                  <template v-slot:append>
                    <v-btn small icon color="primary" @click="setContentColor">
                      <v-icon>mdi-palette</v-icon>
                    </v-btn>
                  </template>
                </v-textarea>
                <v-file-input
                  v-model="form.video"
                  label="上传视频"
                  filled
                  prepend-inner-icon="mdi-video"
                  :prepend-icon="''"
                  accept="video/mp4, video/3gp, video/mpeg"
                  :rules="videoRules"
                  :loading="uploading"
                  :disabled="form.video_link && form.video_link.length > 0"
                  @change="onChangeUploadVideo"
                >
                  <template v-slot:append>
                    <div class="video-uploader">
                      <v-btn
                        text
                        color="primary"
                        @click="uploadVideoFile"
                        :disabled="!form.video"
                      >
                        点击上传
                      </v-btn>
                    </div>
                  </template>
                </v-file-input>
                <div v-if="form.video_link">
                  <v-card class="shadow" color="#f1f1f1">
                    <v-row class="px-3">
                      <v-col cols="4">
                        <v-img
                          :src="form.video_cover"
                          :lazy-src="form.video_cover"
                        />
                      </v-col>
                      <v-col cols="8" class="text-right d-flex align-center">
                        <v-spacer />
                        <v-btn
                          text
                          icon
                          class="rounded"
                          color="error"
                          style="height: 100%;"
                          :loading="loading"
                          @click="deleteVideo(form.id)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
                <v-dialog v-model="showColorPicker" max-width="300">
                  <v-card flat>
                    <v-card-text class="pa-0">
                      <v-color-picker
                        v-if="colorType === 'title'"
                        v-model="form.setting.bg.title_color"
                      ></v-color-picker>
                      <v-color-picker
                        v-else-if="colorType === 'content'"
                        v-model="form.setting.bg.content_color"
                      ></v-color-picker>
                    </v-card-text>
                    <v-card-text
                      class="font-bold"
                      :style="{
                        color:
                          colorType === 'title'
                            ? form.setting.bg.title_color
                            : form.setting.bg.content_color
                      }"
                    >
                      测试{{ colorType === "title" ? "标题" : "内容" }}
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="default" text @click="cancelColor"
                        >取消</v-btn
                      >
                      <v-btn color="primary" text @click="confirmColor"
                        >确定</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-text>
            </v-card>
          </v-stepper-content>
          <v-stepper-step step="3">
            3. 生成二维码
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-card class="shadow rounded mt-2">
              <v-card-text>
                <v-row class="d-flex justify-center">
                  <v-col class="text-center" md="4">
                    <div>
                      <v-btn
                        class="qrcode-button"
                        color="primary"
                        depressed
                        @click="openQrGenerator"
                        >生成二维码</v-btn
                      >
                    </div>
                  </v-col>
                  <v-col class="d-flex justify-center" md="4">
                    <div class="qrcode-preview">
                      <v-img
                        contain
                        class="shadow fill-height"
                        :src="form.qrcode_link"
                        :lazy-src="form.qrcode_link"
                      ></v-img>
                    </div>
                  </v-col>
                  <v-col class="text-center" md="4">
                    <div>
                      <v-btn
                        class="qrcode-button"
                        color="default"
                        depressed
                        @click="downloadQr"
                      >
                        <v-icon>mdi-download</v-icon>
                        下载二维码
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-stepper-content>
        </v-stepper>
        <v-skeleton-loader
          v-else
          type="list-item-avatar-three-line@3"
        ></v-skeleton-loader>
      </v-col>

      <v-card flat class="fill-width">
        <v-card-actions>
          <v-btn
            v-if="currentStep === 2 || currentStep === 3"
            text
            color="primary"
            :loading="loading"
            @click="prevStep"
            >上一步</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveVideo" :loading="loading">
            {{ currentStep === 1 || currentStep === 2 ? "下一步" : "保存" }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <div
        v-show="showSimulator"
        class="position-fixed bottom left-20 z-index-above"
      >
        <simulator
          :tmpBgImage="tmpBgImage"
          :settings="form.setting"
          :title="form.title"
          :content="form.content"
          :poster="form.video_cover"
        />
      </div>
      <v-bottom-sheet v-model="qrGenOpened" inset persistent>
        <v-sheet class="" height="auto">
          <form action="" class="fill-height">
            <v-container class="fill-height">
              <v-card flat width="100%" class="pa-0 ma-0 fill-height">
                <v-card-title
                  ><v-subheader>设置二维码</v-subheader>
                  <v-spacer />
                  <v-btn icon color="primary" @click="qrGenOpened = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="ma-0 pa-0">
                  <v-row class="fill-height">
                    <v-col md="6" sm="6" class="border-right-separator">
                      <v-card flat>
                        <v-list subheader two-line>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-subtitle>
                                设置二维码背景颜色
                              </v-list-item-subtitle>
                              <v-list-item-title>
                                <v-btn-toggle
                                  v-model="qrSetting.bg"
                                  tile
                                  color="deep-purple accent-3"
                                  group
                                >
                                  <template
                                    v-for="(qrBgColor, index) in qrBgColors"
                                  >
                                    <v-btn
                                      :key="index"
                                      depressed
                                      :value="qrBgColor.color"
                                      @click="setQrBgColor(qrBgColor)"
                                    >
                                      <v-icon :color="qrBgColor.name"
                                        >mdi-circle</v-icon
                                      >
                                    </v-btn>
                                  </template>
                                </v-btn-toggle>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-subtitle>
                                设置二维码前景颜色颜色
                              </v-list-item-subtitle>
                              <v-list-item-title>
                                <v-btn-toggle
                                  v-model="qrSetting.fg"
                                  tile
                                  color="deep-purple accent-3"
                                  group
                                >
                                  <template
                                    v-for="(qrBgColor, index) in qrBgColors"
                                  >
                                    <v-btn
                                      :key="index"
                                      depressed
                                      :value="qrBgColor.color"
                                      @click="setQrFgColor(qrBgColor)"
                                    >
                                      <v-icon :color="qrBgColor.name">
                                        mdi-circle
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                </v-btn-toggle>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-subtitle>
                                设置二维码Logo
                              </v-list-item-subtitle>
                              <v-list-item-title class="px-6">
                                <v-switch v-model="qrSetting.logo" />
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-col>
                    <v-col
                      md="6"
                      sm="6"
                      class="display-flex align-center justify-center"
                    >
                      <div class="qrcode-preview">
                        <v-img
                          contain
                          class="shadow fill-height"
                          :src="form.qrcode_link"
                          :lazy-src="form.qrcode_link"
                        ></v-img>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end">
                  <v-btn text color="teal" @click="previewQr">预览</v-btn>
                  <v-btn text color="primary" @click="saveQrCode">保存</v-btn>
                </v-card-actions>
              </v-card>
            </v-container>
          </form>
        </v-sheet>
      </v-bottom-sheet>
      <v-bottom-sheet
        v-model="qrDownloadOpened"
        inset
        persistent
        max-width="400"
      >
        <div style="position: relative;">
          <v-btn
            icon
            color="default"
            style="position: absolute; right: 0;"
            @click="qrDownloadOpened = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-sheet
            class="d-flex align-center justify-md-space-around pa-16"
            height="auto"
          >
            <v-row>
              <v-col cols="12">
                <v-radio-group v-model="qrDownloadSize">
                  <template v-for="(size, index) in qrDownloadSizeOptions">
                    <v-radio
                      v-if="index !== 2"
                      :key="index"
                      :label="size.label"
                      :value="size.value"
                    />
                    <v-radio v-else :key="index" class="mt-5">
                      <template v-slot:label>
                        <v-text-field
                          v-model="size.value"
                          class="text-center"
                          :label="size.label"
                          :style="{ width: '60px' }"
                          type="number"
                          @change="onChangeSize(size.value)"
                        ></v-text-field>
                        *
                        <v-text-field
                          v-model="size.value"
                          :style="{ width: '60px' }"
                          type="number"
                        ></v-text-field>
                      </template>
                    </v-radio>
                  </template>
                </v-radio-group>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn depressed color="primary" @click="confirmDownloadQr">
                  <v-icon>mdi-download</v-icon>
                  下载
                </v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
      </v-bottom-sheet>
    </v-row>
    <v-snackbar v-model="snackBar" color="success">
      {{ noticeText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="default" text v-bind="attrs" @click="snackBar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Simulator from "@/components/Simulator";
import VideoService from "@/services/video.service";

export default {
  name: "Single",

  components: {
    Simulator
  },

  data() {
    return {
      snackBar: false,
      noticeText: null,
      currentStep: 1,
      showSimulator: false,
      showColorPicker: false,
      qrGenOpened: false,
      qrDownloadOpened: false,
      colorType: "title",
      tmpBgImage: "",
      tmpBgColor: "",
      loading: false,
      uploading: false,
      videoRules: [
        value =>
          !value ||
          value.type !== "video/mp4" ||
          value.type !== "video/3gp" ||
          "只支持上传视频文件"
      ],
      form: {
        setting: {
          bg: {
            type: "color",
            value: { r: 236, g: 236, b: 236, a: 1 }
          },
          title_color: { r: 12, g: 12, b: 12, a: 1 },
          content_color: { r: 12, g: 12, b: 12, a: 1 }
        },
        title: "",
        content: "",
        video_link: null,
        video: null,
        qrcode_link: ""
      },
      qrSetting: {
        bg: { r: 239, g: 239, b: 239, a: 1 },
        logo: false,
        fg: { r: 185, g: 185, b: 90, a: 1 }
      },
      qrBgColors: [
        {
          name: "#FFFFFF",
          color: {
            r: 255,
            g: 255,
            b: 255,
            a: 1
          }
        },
        {
          name: "#e2e2e2",
          color: {
            r: 226,
            g: 226,
            b: 226,
            a: 1
          }
        },
        {
          name: "#C8F3C9",
          color: {
            r: 200,
            g: 243,
            b: 201,
            a: 1
          }
        },
        {
          name: "#F1F3C8",
          color: {
            r: 241,
            g: 243,
            b: 200,
            a: 1
          }
        },
        {
          name: "#C25E7C",
          color: {
            r: 194,
            g: 94,
            b: 124,
            a: 1
          }
        },
        {
          name: "#BC3D63",
          color: {
            r: 188,
            g: 61,
            b: 99,
            a: 1
          }
        },
        {
          name: "#BEC4F1",
          color: {
            r: 190,
            g: 196,
            b: 241,
            a: 1
          }
        },
        {
          name: "#000000",
          color: {
            r: 0,
            g: 0,
            b: 0,
            a: 1
          }
        }
      ],
      qrLogo: false,
      currentUploadedVideo: null,
      qrDownloadSizeOptions: [
        {
          label: "100 * 100",
          value: 100
        },
        {
          label: "200 * 200",
          value: 200
        },
        {
          label: "自定义尺寸",
          value: 300
        }
      ],
      qrDownloadSize: 200
    };
  },

  computed: {
    ...mapGetters("video", {
      video: "getVideo"
    })
  },

  watch: {
    video(to) {
      if (to) {
        this.$router
          .replace({
            path: `/project/single/video`,
            query: Object.assign({ vid: to.id }, this.$route.query)
          })
          .then(() => {
            location.reload();
          })
          .catch(() => {});
      }
    }
  },

  created() {
    if (this.$route.query.vid) {
      this.handleFetchVideo(this.$route.query.vid);
    }
  },

  methods: {
    deleteVideo(vid) {
      VideoService.deleteVideoFile(vid).then(() => {
        this.handleFetchVideo(vid);
      });
    },

    handleFetchVideo(vid) {
      this.loading = true;
      this.fetchVideo(vid).then(res => {
        if (!res.setting) {
          this.initVideoSettings(res.id);
        }

        this.form = res;
        this.loading = false;
      });
    },

    initVideoSettings(vid) {
      VideoService.initVideoSettings(vid).then(() => {
        this.fetchVideo();
        // location.reload();
      });
    },

    onChangeUploadVideo(file) {
      this.currentUploadedVideo = file;
    },

    saveQrCode() {
      this.initNoticeBar();
      VideoService.saveQr({
        video_id: this.form.id,
        qrcode: this.form.qrcode_link
      }).then(() => {
        this.snackBar = true;
        this.noticeText = "QrCode is saved";
        // this.form.qrcode_link = res.data.qrcode_link;
        this.qrGenOpened = false;
      });
    },

    setQrBgColor(color) {
      this.qrSetting.bg = color;
    },

    setQrFgColor(color) {
      this.qrSetting.Fg = color;
    },

    openQrGenerator() {
      this.qrGenOpened = true;
    },

    previewQr() {
      let params = Object.assign({ video_id: this.form.id }, this.qrSetting);
      VideoService.generateQr(params).then(res => {
        this.form.qrcode_link = res;
      });
    },

    uploadVideoFile() {
      this.uploading = true;
      VideoService.getSignature({ chapterId: this.$route.query.id }).then(
        res => {
          this.ossUpload(res);
        }
      );
    },

    ossUpload(signature) {
      let formData = new FormData();
      formData.append(
        "key",
        signature.dir + `/${this.currentUploadedVideo.name}`
      );
      formData.append("OSSAccessKeyId", signature.accessid);
      formData.append("policy", signature.policy);
      formData.append("Signature", signature.signature);
      formData.append("file", this.currentUploadedVideo);
      formData.append("success_action_status", 200);

      VideoService.uploadToOss({ host: signature.host, data: formData }).then(
        () => {
          VideoService.updateVideo({
            video_name: this.currentUploadedVideo.name,
            id: this.$route.query.vid
          });
          this.uploading = false;
        }
      );
    },

    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep -= 1;
      }
    },

    addBgImage(file) {
      this.tmpBgImage = URL.createObjectURL(file);
    },

    setTitleColor() {
      this.showColorPicker = true;
      this.colorType = "title";
    },

    setContentColor() {
      this.showColorPicker = true;
      this.colorType = "content";
    },

    confirmColor() {
      this.showColorPicker = false;
    },

    cancelColor() {
      if (this.colorType === "title") {
        this.form.settings.titleColor = "";
      } else {
        this.form.settings.contentColor = "";
      }
      this.showColorPicker = false;
    },

    goToList() {
      this.$router.push({
        path: "/project/single/list",
        query: {
          isNew: this.$route.query.isNew,
          type: "edit",
          id: this.$route.query.id
        }
      });
    },

    saveVideo() {
      this.initNoticeBar();
      if (this.currentStep < 3) {
        if (this.currentStep === 1) {
          if (this.$route.query.vid) {
            this.form.id = this.$route.query.vid;
          }

          this.loading = true;
          this.setVideo(
            Object.assign(this.form, { chapter_id: this.$route.query.id })
          ).then(res => {
            this.snackBar = true;
            this.noticeText = "Video added, please go on editing";
            this.fetchVideo(res.id).then(() => {
              this.loading = false;
            });
          });
        }
        this.currentStep += 1;
      } else {
        this.setVideo({
          id: this.$route.query.vid,
          chapter_id: this.$route.query.id,
          title: this.form.title,
          content: this.form.content
        }).then(() => {
          this.$router.push({
            path: "/project/single/list",
            query: { id: this.$route.query.id, isNew: 1, type: "edit" }
          });
        });
      }
    },

    initNoticeBar() {
      this.snackBar = false;
      this.noticeText = null;
    },

    downloadQr() {
      this.qrDownloadOpened = true;
    },

    onChangeSize(size) {
      this.qrDownloadSize = size;
    },

    confirmDownloadQr() {
      console.log(this.qrDownloadSize);
      window.open(this.form.qrcode_link, "_blank");

      // if ()
      // VideoService.downloadQr({ video_id: this.$route.query.vid }).then(res => {
      //   console.log(res);
      // });
    },

    ...mapActions("video", ["setVideo", "fetchVideo"])
  }
};
</script>

<style scoped lang="scss">
.qrcode-preview,
.qrcode-button {
  width: 150px;
  height: 150px !important;
}
.border-right-separator {
  border-right: 1px solid #e3e3e3;
}

.video-uploader {
  margin-top: -6px !important;
}
</style>
