<template>
  <div
    class="simulator"
    :style="
      tmpBgImage !== '' && settings && settings.bg.type === 'image'
        ? { background: `#fff url(` + tmpBgImage + `) no-repeat center` }
        : {
            background: settings ? formattedColor(settings.bg.value) : '#FFFFFF'
          }
    "
  >
    <div class="simulator-title">
      <div
        class="simulator-title-wrapper subtitle-2 font-weight-bold"
        :style="{
          color: settings ? formattedColor(settings.bg.title_color) : '#686868'
        }"
      >
        {{ title }}
      </div>
    </div>
    <div class="simulator-video mt-30">
      <v-img v-if="poster" :src="poster" :lazy-src="poster" height="200" />
      <!--      <video-player-->
      <!--        class="video-player-box"-->
      <!--        ref="videoPlayer"-->
      <!--        :options="options"-->
      <!--        :playsinline="true"-->
      <!--        @play="onPlayerPlay($event)"-->
      <!--        @pause="onPlayerPause($event)"-->
      <!--        @statechanged="playerStateChanged($event)"-->
      <!--        @ready="playerReadied"-->
      <!--      />-->
    </div>
    <div
      class="simulator-content"
      :style="{
        color: settings ? formattedColor(settings.bg.content_color) : '#686868'
      }"
    >
      <v-row>
        <v-col md="10" class="caption font-weight-bold">
          <div class="content-container">
            {{ content }}
          </div>
        </v-col>
        <v-col md="2">
          <div class="mb-3"><v-icon>mdi-thumb-up-outline</v-icon></div>
          <div class="mb-3"><v-icon>mdi-share-variant</v-icon></div>
          <div><v-icon>mdi-heart-outline</v-icon></div>
        </v-col>
      </v-row>
    </div>
    <v-bottom-navigation class="simulator-nav" absolute>
      <v-btn value="recent">
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>

      <v-btn value="favorites">
        <v-icon>mdi-youtube-tv</v-icon>
      </v-btn>

      <v-btn value="nearby">
        <v-icon>mdi-account-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
// import "video.js/dist/video-js.css";
// import { videoPlayer } from "vue-video-player";

export default {
  name: "simulator",

  props: {
    settings: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: "视频标题"
    },
    content: {
      type: String,
      default: "视频简介"
    },
    tmpBgImage: {
      type: String,
      default: ""
    },
    poster: {
      type: String,
      default: null
    }
  },

  components: {
    // videoPlayer
  },

  data() {
    return {
      options: {
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src:
              "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
          }
        ],
        poster: "/static/images/author.jpg"
      }
    };
  },

  computed: {
    formattedColor() {
      return color => {
        if (typeof color === "object") {
          return `rgba(${color.r},${color.g},${color.b},${color.a})`;
        }

        return color;
      };
    }
  },

  methods: {
    // listen event
    onPlayerPlay(player) {
      console.log("player play!", player);
    },
    onPlayerPause(player) {
      console.log("player pause!", player);
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      console.log("player current update state", playerCurrentState);
    },

    // player is ready
    playerReadied(player) {
      console.log("the player is readied", player);
      // you can use it to do something...
      // player.[methods]
    }
  }
};
</script>

<style lang="scss">
.simulator {
  width: 300px;
  height: 600px;
  border: white 10px solid;
  margin: 0px auto;
  border-radius: 40px;
  background: whitesmoke;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  display: block;
  overflow-y: scroll;
  padding: 30px 0;
  &-title {
    text-align: left;
    padding: 10px 20px;
    &-wrapper {
      background-color: #ededed;
      border-radius: 0 10px;
      padding: 8px;
    }
  }
  &-video {
    .video-player {
      width: 100%;
      height: 200px;
      #vjs_video_3 {
        width: 100%;
        height: 100%;
      }
    }
  }
  &-content {
    text-align: left;
    padding: 30px 20px;
  }
  &-nav {
    border-radius: 0 0 29px 29px;
    width: 94% !important;
    left: 10px !important;
    bottom: 10px !important;
    box-shadow: none !important;
  }
  .content-container,
  .title-container {
    background-color: #ededed;
    border-radius: 0 10px;
    padding: 15px;
  }
}
</style>
